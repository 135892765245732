// Libs.
import React from 'react';
import { Router } from '@reach/router';
import { useAuthUserCheckOrError } from '../libs/handleHttpError';
// Components.
import SEO from '../components/layout/seo';
import MemberLayout from '../components/layout/member-layout';
import OrderHistoryDetail from '../components/order-history/order-history-detail';

const AccountOrderHistoryDetailPage = ({ location }) => {
  useAuthUserCheckOrError();

  return (
    <MemberLayout containered={false}>
      <SEO title="Transaction Detail" />
      <Router location={location} basepath={__BASE_PATH__}>
        <OrderHistoryDetail path="/account-order-history/detail/:id" />
      </Router>
    </MemberLayout>
  );
};

export default AccountOrderHistoryDetailPage;
